.cs-message-list {
  background-color: var(--bg) !important;
}

.cs-main-container {
  border: none !important;
}

.scrollbar-container {
  padding-left: 0px !important;
}

.ps__thumb-y {
  background-color: var(--border) !important;
}

.ps__rail-y:hover,
.ps__rail-y:active,
.ps__rail-y:focus {
  background-color: transparent !important;
}

.cs-message__sent-time {
  color: var(--text-grey) !important;
}

.cs-message-list__scroll-wrapper > .cs-message {
  margin-bottom: 0px !important;
}

.cs-message--incoming .cs-message__sent-time {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.cs-message__content {
  background-color: transparent !important;
}
