.body {
  background-image: url('../../../../public/img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &_inner {
    max-width: 416px;
  }

  &_icon {
    max-width: 320px;
    width: 100%;
  }

  &_text {
    color: var(--text-white);

    font-size: 24px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  &_progress_bar_count {
    color: var(--text-white);
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 24px;
  }

  &_progress_bar {
    width: 311px;
    width: 100%;
    height: 9px;
    border-radius: 5px;
    background-color: var(--bg-secondary2);
    position: relative;

    .animation &::after {
      animation-duration: 2s;
      animation-name: animation_bar;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0%;
      top: 0%;
      height: 9px;
      width: 0%;
      border-radius: 5px;
      background-image: url('../../../../public/img/progress-loader.svg');
    }
  }
}

.fullscreen {
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

@keyframes animation_bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
