@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

:root {
  --bg: #161c2b;
  --bg-secondary: #1b2132;
  --bg-secondary2: #222a3f;
  --bg-secondary3: #2e3957;
  --bg-overlay: #10141ef0;
  --action-btn-border: #232a40;
  --action-btn-bg: #1b2132;
  --action-btn-gradient-bg: linear-gradient(
      180deg,
      #148eff -84.09%,
      #1463ff 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );

  --action-btn-gradient-border: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --border: #232b3e;

  --tabs--linear-bg: linear-gradient(180deg, #148eff -84.09%, #1463ff 100%),
    linear-gradient(
      180deg,
      rgba(240, 242, 250, 0.1) 0%,
      rgba(240, 242, 250, 0) 100%
    );
  --tabs-box-shadow: rgba(21, 103, 255, 0.2);

  --text-white: #ffffff;
  --text-grey: #4f5a7c;
  --text-grey-02: #7784a7;
  --text-blue: #148eff;
  --text-light-green: #5dc983;
  --text-light-red: #ff5f5f;

  --green-op-01: rgba(93, 201, 131, 0.102);
  --green-op-02: rgba(93, 201, 131, 0.202);
  --red-op-01: rgba(201, 93, 93, 0.102);
  --red-op-02: rgba(201, 93, 93, 0.202);
  --blue-op-01: rgba(20, 142, 255, 0.102);
  --blue-op-02: rgba(20, 142, 255, 0.202);

  --btn-green-gradient: linear-gradient(180deg, #5dc983 -84.09%, #04b342 100%),
    linear-gradient(
      180deg,
      rgba(240, 242, 250, 0.1) 0%,
      rgba(240, 242, 250, 0) 100%
    );
  --btn-red-gradient: linear-gradient(
      180deg,
      rgba(240, 242, 250, 0.1) 0%,
      rgba(240, 242, 250, 0) 100%
    ),
    linear-gradient(180deg, #ff5f5f -84.09%, #cc2c2c 100%);

  --btn-blue-gradient: linear-gradient(180deg, #148eff -84.09%, #1463ff 100%),
    linear-gradient(
      180deg,
      rgba(240, 242, 250, 0.1) 0%,
      rgba(240, 242, 250, 0) 100%
    );

  --blue-border-gradient: linear-gradient(
    180deg,
    #148eff 0%,
    rgba(20, 142, 255, 0) 100%
  );

  --blue-tooltip-border-gradient: linear-gradient(
      180deg,
      #148eff 0%,
      rgba(20, 142, 255, 0.2) 100%
    ),
    linear-gradient(0deg, #222a3f, #222a3f);

  --drop-shadow-blue: #1567ff4d;

  --header-fs-main: 14px;
  --header-fs-secondary: 12px;
  --fs-main: 14px;
  --fs-secondary: 12px;

  --rate-width: 380px;
  --sidebar-width: 360px;

  --gb-blue-btn-gradient: linear-gradient(
      180deg,
      rgba(20, 142, 255, 0) 0%,
      rgba(20, 142, 255, 0.2) 100%
    ),
    linear-gradient(180deg, #148eff 0%, rgba(20, 142, 255, 0) 100%),
    linear-gradient(0deg, #1b2132, #1b2132);
}

@media (max-width: 1650px) {
  :root {
    --rate-width: 330px;
    --sidebar-width: 300px;
  }
}

@media (max-width: 1350px) {
  :root {
    --rate-width: 330px;
    --sidebar-width: 300px;
  }
}

body {
  background-color: var(--bg);
}

* {
  font-family: 'Geologica';
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  user-select: none;
}

input {
  border: none;
  outline: none;
}

textarea:focus,
input:focus {
  font-family: 'Geologica';
  outline: none;
}

input::placeholder {
  font-family: 'Geologica';
}

html {
  overflow: hidden;
  user-select: none;
}

body {
  overflow: hidden;
}

button {
  border: none;
  outline: none;
  background-color: inherit;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:active,
input:focus {
  border: 0;
  outline: 0;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

// ::-webkit-scrollbar-track {
//   background: var(--bg-secondary);
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--border);
// }

.global_grid {
  display: grid;
  grid-template-columns: var(--rate-width) 1fr var(--sidebar-width);
}

.default_border {
  border: 2px solid var(--action-btn-border);
}

.default_settings_active {
  & svg path {
    stroke: var(--text-blue);
  }
  & > div {
    background: var(--blue-border-gradient) !important;
  }
  & div > div {
    opacity: 0.7;
  }
}

.default_hover {
  cursor: pointer;
  @media (any-hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
}

#ambient_sound {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

@media (max-width: 1350px) {
  .global_grid {
    grid-template-columns: 0px 1fr 0px;
  }
}

.hover_standart {
  transition: 0.5s;
  cursor: pointer;
}

.hover_standart:hover {
  transition: 0.5s;
  opacity: 0.7;
}
