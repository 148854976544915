.epr-emoji-category-label,
.epr-category-nav {
  display: none !important;
}

.epr-btn {
  @media (any-hover: hover) {
    &:hover {
      background-color: var(--bg-secondary) !important;
    }
  }
}

.epr-main {
  background-color: var(--bg-secondary2) !important;
  border: none !important;
  & > div:nth-child(3) {
    display: none !important;
  }
}

.epr-header {
  & > div:nth-child(1) {
    padding: 0px 0px 20px;
  }
}
.epr-search-container {
  & .epr-btn {
    display: none !important;
  }

  & input {
    background-color: var(--bg-secondary);
    border: none;
    padding: 14.5px 14px 14.5px 46px;

    border-radius: 12px;

    color: var(--text-white);
    font-size: var(--fs-secondary);
    font-weight: 300;
    line-height: 15px;
    letter-spacing: -0.02em;

    height: 44px;
    &::placeholder {
      color: var(--text-grey);
      font-size: var(--fs-secondary);
      font-weight: 300;
      line-height: 15px;
      letter-spacing: -0.02em;
    }

    &:focus {
      background-color: var(--bg-secondary);
      border: none;
    }
  }
}

.epr-emoji-list {
  margin: 0px -16px !important;
}

.epr-icn-search {
  background-image: url('../../public/img/chat/search-normal.svg') !important;
  background-position-y: 0px !important;
}
